import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFormattedEmployeeMessages } from 'appRedux/owner/Messages/selector';
import { setSnackbarError } from 'appRedux/actions';

export const requestGetMessages = createAsyncThunk(
  'messages/getMessages',
  async (_, { extra, dispatch, getState }) => {
    const { employee: { messages } } = getState();
    try {
      const params = {
        page: messages.page,
        per_page: messages.perPage,
      };
      const response = await extra.axios.get('/api/v3/employee/conversations/conversation', { params });
      const { records, ...rest } = getFormattedEmployeeMessages(response.data.data);
      return { records, filters: rest };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_employees_messages_failed'));
    }
  },
);

export const requestSendMessageToAdmin = createAsyncThunk(
  'messages/sendMessage',
  async (data, { extra, dispatch }) => {
    try {
      const body = {
        message: {
          body: data.message,
          reply_to_message_id: data.replyMsgId,
        },
      };
      await extra.axios.post('/api/v3/employee/conversations', body);
      dispatch(requestGetMessages(data.employeeId));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_send_msg_employee_failed'));
    }
  },
);

export const requestMarkEmployeeConversationsAsRead = createAsyncThunk(
  'messages/markAsRead',
  async (data, { extra, dispatch }) => {
    try {
      const updatePromises = data.ids.map((id) => {
        const body = {
          message: {
            read: true,
          },
        };
        return extra.axios.put(`/api/v3/conversations/${id}`, body);
      });

      await Promise.all(updatePromises);
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_message_mark_all_read_error'));
    }
  },
);
