import axios from 'utils/Api';
import axiosNext from 'utils/axios';
import { batch } from 'react-redux';
import { map, noop, get, orderBy } from 'utils/lodash';
import { bookingStatus, names } from 'utils/enum';
import { fm } from 'utils/string';
import camelize from 'camelize';
import { getValidatedFormattedBooking } from 'appRedux/thunks/bookings/validator';
import { multiStateGet } from 'appRedux/reducers/utility';
import { getCopyBookingData } from 'appRedux/thunks/calendar/selector';
import {
  setFilters,
  setBookingsFields,
  setSnackbarError,
  setSnackbarSuccess,
  setCalendarFields,
  setBookingsItemsFields,
  setInfoModalFields,
  setBookingInvoiceIdFields,
} from 'appRedux/actions';
import { setProposalsFields } from 'appRedux/owner/proposals';
import { requestUpdateProposal } from 'appRedux/owner/proposals/thunk';
import { proposalStatusTypes } from 'components/Proposal/utility';
import { setEbokaBookingsFields } from 'appRedux/owner/eboka/bookings';
import {
  getFormattedBookingData,
  formatBookingListResponse,
  getFormattedBookingFilterParams,
  getBookingActivity,
  getFormattedPredefinedChecklist,
} from './selector';
import { requestUpdateRecurringBooking } from './recurring';
import { requestBookingIdsAttachmentsStatus } from './attachments';

export * from './attachments';
export * from './email';

export const requestCreateBooking = (booking) => async (dispatch, getState) => {
  try {
    const { eboka: { bookings }, proposals: { proposal } } = getState();
    dispatch(setBookingsFields({ fsip: true }));
    await axios.post('/api/v3/bookings/booking_create', booking);
    dispatch(setSnackbarSuccess('snackbar.booking.created'));
    batch(() => {
      if (booking.booking.proposal_id) {
        dispatch(setProposalsFields({ showProposalDialog: false, refreshAllProposals: {} }));
        dispatch(requestUpdateProposal({ ...proposal, status: proposalStatusTypes.approved }));
      }
      if (bookings.openDialog) {
        dispatch(setEbokaBookingsFields({ selectedBookingId: null, openDialog: false }));
      }
      dispatch(setBookingsFields({
        refreshAllBookings: {},
        showBookingModal: false,
        openedBookingId: null,
        bookingModalError: null,
        booking: {},
        fsip: false,
      }));
    });
  } catch (err) {
    dispatch(setBookingsFields({ fsip: false }));
    if (err.name === 'ValidationError') dispatch(setBookingsFields({ bookingModalError: err.message }));
  }
};

export const requestUpdateBooking = (bookingDetails, sequenceNum) => async (dispatch) => {
  try {
    dispatch(setBookingsFields({ fsip: true }));
    await axios.put(`/api/v3/bookings/${sequenceNum}/simple_update`, bookingDetails);
    dispatch(setSnackbarSuccess('snackbar.booking.updated'));
    dispatch(setBookingsFields({
      refreshAllBookings: {},
      showBookingModal: false,
      openedBookingId: null,
      bookingModalError: null,
      booking: {},
      fsip: false,
    }));
  } catch (err) {
    dispatch(setBookingsFields({ fsip: false }));
    setSnackbarError('snackbar.booking.updated.error');
  }
};

export const validateAndCreateOrUpdateBooking = (bookingDetails) => async (dispatch, getState) => {
  try {
    const booking = await getValidatedFormattedBooking(bookingDetails);
    // check if booking needs to be updayted
    if (bookingDetails.sequenceNum) {
      const { bookings: { originalBooking } } = getState();
      // check if the booking is recurring booking
      if (bookingDetails.isRecurring || bookingDetails.isRecurringInstance) {
        // check if the endDate has been extended for the booking
        if (bookingDetails.endDate === originalBooking.endDate) {
          // if the endDate is not extended then show options for current, future and all.
          return dispatch(setBookingsFields({ showRecurringUpdateOptionsModal: true }));
        }
        // if the endDate is extended then diretly update the booking
        return dispatch(requestUpdateRecurringBooking(bookingDetails));
      }
      // if it's not recurring booking just update it
      dispatch(requestUpdateBooking(booking, bookingDetails.sequenceNum));
    } else {
      // create new booking
      dispatch(requestCreateBooking(booking));
    }
  } catch (err) {
    if (err.name === 'ValidationError') dispatch(setBookingsFields({ bookingModalError: err.message }));
  }
};

export const requestBooking = (bookingId) => async (dispatch, getState) => {
  const { bookings: { bookingLocalUpdates } } = getState();
  try {
    dispatch(setBookingsFields({ fetchingBooking: true }));
    const response = await axiosNext.get(`/api/v1/bookings/${bookingId}`);
    let booking = getFormattedBookingData(response.data.data);
    if (bookingLocalUpdates) booking = { ...booking, ...bookingLocalUpdates };
    dispatch(setBookingsFields({
      booking,
      originalBooking: Object.freeze(booking),
      fetchingBooking: false,
      bookingLocalUpdates: null,
    }));
  } catch (err) {
    if (get('response.status')(err) === 404) {
      dispatch(setBookingsFields({
        showBookingModal: false,
        openedBookingId: null,
        fsip: false,
        fetchingBooking: false,
      }));

      dispatch(setInfoModalFields({
        callBack: noop,
        message: fm('booking_does_not_exist'),
        open: true,
        title: fm('booking'),
      }));
    }
    dispatch(setSnackbarError('snackbar.booking.getBooking.error'));
  }
};

export const requestUpdateBookingInvoiveIdNumber = (bookingId, updatedInvoiceId) => async (dispatch, getState) => {
  const { bookings: { booking } } = getState();
  try {
    const body = { invoice_id: updatedInvoiceId };
    await axios.put(`/api/v3/bookings/${bookingId}/set_invoice_id`, body);
    dispatch(setSnackbarSuccess('snackbar_invoiceid_updated'));
    dispatch(setBookingInvoiceIdFields({
      updateInvoiceNumberPopoverError: null,
    }));
    dispatch(setBookingsFields({
      refreshAllBookings: {},
      booking: { ...booking, invoiceId: body.invoice_id },
    }));
    return dispatch(setBookingInvoiceIdFields({ showInvoiceIdDialog: false }));
  } catch (err) {
    if (get('response.data.errors[0].details.fortnox_id[0].code')(err)) {
      dispatch(setBookingInvoiceIdFields({
        updateInvoiceNumberPopoverError: fm(get('response.data.errors[0].details.fortnox_id[0].code')(err)),
      }));
    }
  }
};

export const requestDeleteBookings = (ids) => async (dispatch) => {
  try {
    const response = await axios.delete('/api/v3/bookings/bulk_delete', { data: { bookings: ids } }); // eslint-disable-line
    dispatch(setBookingsFields({ openedBookingId: null, refreshAllBookings: {}, showBookingModal: false }));
    dispatch(setSnackbarSuccess('snackbar.booking.deleted'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.delete.error'));
  }
};

export const requstFilteredBookings = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const parameters = getFormattedBookingFilterParams(multiStateGet(name, filters));
    const response = await axios.get('/api/v3/bookings/search', { params: parameters });
    const result = get('data.data')(response);
    const { records, ...rest } = formatBookingListResponse(result);
    batch(() => {
      dispatch(setBookingsFields({ records, selectedBookingIds: [] }));
      dispatch(requestBookingIdsAttachmentsStatus(records));
      dispatch(setFilters({ ...rest, ...(rest.page > rest.pages && { page: rest.pages }), name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.getBookings.error'));
    return dispatch;
  }
};

export const addPredefinedListToBooking = (taskCategory) => async (dispatch, getState) => {
  const { bookings: { booking } } = getState();
  try {
    const response = await axios.get('/api/v3/checklists/tasks', { params: { task_category_id: taskCategory.id } });
    const { checklist } = getFormattedPredefinedChecklist(response.data.data);
    const { bookingChecklists = [] } = booking;
    const newBookingChecklists = [...bookingChecklists, checklist];
    dispatch(setBookingsFields({ booking: { ...booking, bookingChecklists: newBookingChecklists } }));
  } catch (err) {
    dispatch(setSnackbarError('snackbarGetPredefinedChecklistTaskError'));
  }
};

export const requestCompleteBooking = (bookingId, callback) => async (dispatch, getState) => {
  const { bookings, calendar: { events: calendarEvents = {} } } = getState();
  try {
    await axios.put(`/api/v3/bookings/${bookingId}/status/completed`);
    const status = bookingStatus.completed;
    const records = map((b) => (b.sequenceNum !== bookingId ? b : ({ ...b, status })))(bookings.records);

    const events = Object.keys(calendarEvents).reduce((res, employeeId) => {
      res[employeeId] = map((b) => (b.sequenceNum !== bookingId ? b : ({ ...b, status })))(calendarEvents[employeeId]);
      return res;
    }, {});

    batch(() => {
      dispatch(setBookingsFields({ records }));
      dispatch(setBookingsItemsFields({ expandedRowsIndexes: {} }));
      dispatch(setCalendarFields({ events }));
      if (bookings.showBookingModal) {
        const booking = { ...bookings.booking, status: bookingStatus.completed };
        dispatch(setBookingsFields({ booking }));
      }
      dispatch(setSnackbarSuccess('snackbar.booking.completed'));
    });

    return callback && callback();
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.code')(err);
    if (errorCode) {
      return dispatch(setSnackbarError(fm(errorCode)));
    }
    dispatch(setSnackbarError('snackbar.booking.complete.error'));
    return dispatch;
  }
};

export const requestCompleteBookingTask = (taskId, completed) => async (dispatch) => {
  try {
    const params = { booking_checklist: { completed } };
    await axios.put(`/api/v3/booking_checklists/${taskId}`, params);
  } catch (err) {
    dispatch(setSnackbarError('snackbarBookingTaskMarkError'));
  }
};

export const requestActivateBooking = (bookingId) => async (dispatch, getState) => {
  const { bookings, calendar: { events: calendarEvents = {} } } = getState();
  try {
    await axios.put(`/api/v3/bookings/${bookingId}/status/active`);
    const status = bookingStatus.active;
    const records = map((b) => (b.sequenceNum !== bookingId ? b : ({ ...b, status })))(bookings.records);

    const events = Object.keys(calendarEvents).reduce((res, employeeId) => {
      res[employeeId] = map((b) => (b.sequenceNum !== bookingId ? b : ({ ...b, status })))(calendarEvents[employeeId]);
      return res;
    }, {});

    batch(() => {
      dispatch(setBookingsItemsFields({ expandedRowsIndexes: {} }));
      dispatch(setBookingsFields({ records, refreshAllBookings: {} }));
      dispatch(setCalendarFields({ events }));
      if (bookings.showBookingModal) {
        const booking = { ...bookings.booking, status: bookingStatus.active };
        dispatch(setBookingsFields({ booking }));
      }
      dispatch(setSnackbarSuccess('snackbar.booking.activated'));
    });
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.code')(err);
    if (errorCode) {
      return dispatch(setSnackbarError(fm(errorCode)));
    }
    dispatch(setSnackbarError('snackbar.booking.activated.error'));
    return dispatch;
  }
};

export const requestCancelBooking = (bookingId) => async (dispatch, getState) => {
  const { bookings, calendar: { events: calendarEvents = {} } } = getState();
  try {
    await axios.put(`/api/v3/bookings/${bookingId}/status/canceled`);
    const records = map((b) => (
      b.sequenceNum !== bookingId ? b : ({ ...b, status: bookingStatus.cancelled })))(bookings.records);
    const events = Object.keys(calendarEvents).reduce((res, employeeId) => {
      res[employeeId] = map((b) => (
        b.sequenceNum !== bookingId ? b : ({ ...b, status: bookingStatus.cancelled })))(calendarEvents[employeeId]);
      return res;
    }, {});

    batch(() => {
      dispatch(setBookingsItemsFields({ expandedRowsIndexes: {} }));
      dispatch(setBookingsFields({ records }));
      dispatch(setCalendarFields({ events }));
      if (bookings.showBookingModal) {
        const booking = { ...bookings.booking, status: bookingStatus.cancelled };
        dispatch(setBookingsFields({ booking }));
      }
      dispatch(setSnackbarSuccess('snackbar.booking.cancelled'));
    });
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.code')(err);
    if (errorCode) {
      return dispatch(setSnackbarError(fm(errorCode)));
    }
    dispatch(setSnackbarError('snackbar.booking.cancel.error'));
  }
};

export const requestCreateComment = (txt, bookingId) => async (dispatch, getState) => {
  const { bookings: { records: bookings, comments = {} } } = getState();
  const { records: prevRecords = [] } = comments;
  try {
    const params = { comment: { body: txt } };
    const response = await axios.post(`/api/v1/bookings/${bookingId}/comments`, params);
    const records = [...prevRecords, camelize(response.data.data)];
    const bookingRecords = bookings && bookings.map((b) => (
      b.sequenceNum !== bookingId ? b : ({ ...b, commented: true })));
    dispatch(setBookingsFields({ records: bookingRecords, comments: { records } }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.message.error'));
  }
};

export const requestComments = (bookingId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/bookings/${bookingId}/comments`);
    if (response.data.data.records) {
      const records = camelize(response.data.data.records);
      const orderedComments = orderBy((m) => new Date(m.createdAt), 'asc')(records) || [];
      dispatch(setBookingsFields({ comments: { records: orderedComments } }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.getMessages.error'));
  }
};

export const requestMarkCommentsAsRead = (ids) => async () => {
  try {
    const comments = { comment: { ids } };
    await axios.get('/api/v1/comment/mark_all_as_read', comments);
  } catch (err) {
    // comment
  }
};

// export const requestAllUnreadComments = ids => async (dispatch, getState) => {
export const requestAllUnreadComments = () => async (dispatch, getState) => {
  const { bookings = {} } = getState();
  try {
    const pageParams = { page: bookings.commentsPage, per_page: 10 };
    const response = await axios.get('/api/v1/comment/all_comments', { params: pageParams });
    const allComments = get('data.data.records')(response) || [];
    const totalComments = get('data.data.total_records')(response);
    const commentsPage = get('data.data.page')(response);
    dispatch(setBookingsFields({ allComments, totalComments, commentsPage }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.getUnreadMessages.error'));
  }
};

export const requestBookingActivity = (bookingId) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const bookingActivityFilters = multiStateGet(names.bookingActivity, filters);
    const query = {
      per_page: bookingActivityFilters.perPage,
      page: bookingActivityFilters.page,
    };
    const response = await axios.get(`/api/v3/bookings/${bookingId}/activities`, { params: query });
    const activities = getBookingActivity(response.data.data);
    dispatch(setBookingsFields({ activities }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.getBookingActivity.error'));
  }
};

export const requestCompleteBookings = (bookingIds) => async (dispatch, getState) => {
  const { bookings } = getState();
  try {
    await axios.put('/api/v3/bookings/status_bulk/completed', { bookings: bookingIds });
    const records = map((b) => (
      !bookingIds.includes(b.sequenceNum) ? b : ({ ...b, status: bookingStatus.completed })))(bookings.records);
    dispatch(setBookingsFields({ records }));
    dispatch(setSnackbarSuccess('snackbar.booking.completed'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.complete.error'));
  }
};

export const requestCancelBookings = (bookingIds) => async (dispatch, getState) => {
  const { bookings } = getState();
  try {
    await axios.put('/api/v3/bookings/status_bulk/canceled', { bookings: bookingIds });
    const records = map((b) => (
      !bookingIds.includes(b.sequenceNum) ? b : ({ ...b, status: bookingStatus.cancelled })))(bookings.records);
    dispatch(setBookingsFields({ records }));
    dispatch(setSnackbarSuccess('snackbar.booking.completed'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.complete.error'));
  }
};

export const requestBookingBulkReactivate = (bookingIds) => async (dispatch, getState) => {
  const { bookings } = getState();
  try {
    await axios.put('/api/v3/bookings/status_bulk/active', { bookings: bookingIds });
    const records = map((b) => (
      !bookingIds.includes(b.sequenceNum) ? b : ({ ...b, status: bookingStatus.active })))(bookings.records);
    dispatch(setBookingsFields({ records }));
    dispatch(setSnackbarSuccess('snackbar_bookings_reactivate_success'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_bookings_reactivate_error'));
  }
};

export const requestApproveAllTime = (emp, callback) => async (dispatch, getState) => {
  const { bookings } = getState();
  try {
    const reqBody = { bookings_employees_ids: [emp.bookingEmployeesId] };
    await axios.put('/api/v1/time_histories/bulk_approve', reqBody);
    dispatch(setSnackbarSuccess('snackbar.timeManagement.timeApproveBulk'));
    const { booking = {} } = bookings;
    const bookingEmployee = { ...emp, timeRequestStatus: true };
    const newBooking = { ...booking };
    newBooking.bookingEmployees = newBooking.bookingEmployees.map((employee) => (
      employee.employeeId === bookingEmployee.employeeId ? bookingEmployee : employee));
    dispatch(setBookingsFields({ booking: newBooking }));
    return callback && callback();
  } catch (err) {
    setSnackbarError('snackbar.timeManagement.timeApprove.error');
    return dispatch;
  }
};

export const requestBookingToCopy = (bookingSequenceNum) => async (dispatch) => {
  try {
    const response = await axiosNext.get(`/api/v1/bookings/${bookingSequenceNum}`);
    const booking = getCopyBookingData(response.data.data);
    dispatch(setBookingsFields({ booking, openedBookingId: null }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.calendar.copy.booking.error'));
  }
};
