import { createSlice } from '@reduxjs/toolkit';
import {
  requestGetMessages,
} from './thunk';

const initialState = {
  page: 1,
  perPage: 10,
  records: [],
};

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessagesFields(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestGetMessages.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.filters,
      records: action.payload.records,
    }));
  },
});

const { reducer, actions } = messages;

export const { setMessagesFields } = actions;

export default reducer;
