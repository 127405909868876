import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestGetEmployees,
  requestMessages,
  requestSendMessageToMultipleEmployees,
} from './thunk';

const initialState = {
  employees: [],
  message: {
    page: 1,
    perPage: 10,
    records: [],
  },
};

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessagesFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetMessagesListFields (state) {
      return {
        ...state,
        message: {
          page: 1,
          perPage: 10,
          records: [],
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestGetEmployees.fulfilled, (state, action) => ({
      ...state,
      employees: action.payload,
    }));
    builder.addCase(requestMessages.fulfilled, (state, action) => ({
      ...state,
      message: {
        ...state.message,
        ...action.payload.filters,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestSendMessageToMultipleEmployees.fulfilled, (state) => ({
      ...state,
      openSendNewMessageModal: false,
    }));
  },
});

const { reducer, actions } = messages;

export const { setMessagesFields, resetMessagesListFields } = actions;

export default reducer;
