import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fm } from 'utils/string';
import { useFormik } from 'formik';
import { requestChangePassword, requestUpdatePasswordByDefaultPassword } from 'appRedux/thunks/auth';
import LoadingButton from 'components/common/LoadingButton';
import { Grid, TextField, Typography, Tooltip, ErrorMessage } from 'components/library';
import { PasswordIcon, EditIcon } from 'components/library/icons';
import { setAuthFields } from 'appRedux/actions';
import ChangePasswordLayout from '../ChangePasswordLayout';
import validationSchema from './validationSchema';

export default function NewPasswordForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const otp = sessionStorage.getItem('reset_password_otp');
  const {
    fsip,
    resetPasswordError,
    loginAfterSetPasswordByOtp,
    loginAfterSetPasswordByDefaultPassword,
  } = useSelector((state) => state.auth);

  const onSubmit = (formData) => {
    const { password, confirmPassword, currentPassword } = formData;
    dispatch(setAuthFields({ resetPasswordError: null }));
    if (loginAfterSetPasswordByOtp) {
      dispatch(requestChangePassword({ password, confirmPassword }, props.history));
    } else {
      dispatch(requestUpdatePasswordByDefaultPassword(
        { password, confirmPassword, currentPassword },
        props.history,
      ));
    }
  };

  const { values, handleChange, errors, ...formik } = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  });

  return (
    <ChangePasswordLayout
      icon={<PasswordIcon fontSize="medium" />}
      title={fm('set_new_password', 'Set new password')}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {loginAfterSetPasswordByOtp && (
            <Grid item xs={12} display="flex">
              <Typography mr={1} mt={0.2}>OTP: {otp}</Typography>
              <Tooltip title={fm('edit_otp')}>
                <EditIcon
                  fontSize="small"
                  className="pointer"
                  onClick={() => history.goBack()}
                />
              </Tooltip>
            </Grid>
          )}
          {loginAfterSetPasswordByDefaultPassword && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                name="currentPassword"
                id="current_password"
                label={fm('current_password')}
                fullWidth
                value={values.currentPassword}
                type="password"
                onChange={handleChange}
                error={Boolean(errors.currentPassword)}
                helperText={errors.currentPassword || fm('password_must_be_8_letter')}
                placeholder={fm('current_password')}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              name="password"
              id="password"
              label={fm('password')}
              fullWidth
              value={values.password}
              type="password"
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password || fm('password_must_be_8_letter')}
              placeholder={fm('password')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              name="confirmPassword"
              id="confirmPassword"
              label={fm('confirm_password')}
              fullWidth
              value={values.confirmPassword}
              type="password"
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword}
              onChange={handleChange}
              placeholder={fm('confirm_password')}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <ErrorMessage text={resetPasswordError} />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton fullWidth type="submit" loading={fsip}>
              {fm('reset_password')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </ChangePasswordLayout>
  );
}

NewPasswordForm.propTypes = {
  history: PropTypes.shape({}),
};
