import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError } from 'appRedux/actions';
import { getFormattedEmployeeMessages, getFormattedBodyForMessageToEmployees } from './selector';

export const requestGetEmployees = createAsyncThunk(
  'messages/getEmployees',
  async (employee, { extra, dispatch }) => {
    try {
      const params = {
        search_query: employee?.name || '',
      };
      const response = await extra.axios.get('/api/v3/conversations/user_conversations', { params });
      const records = response.data.data;
      if (employee?.id) {
        records.sort((a, b) => {
          if (a.userId === employee?.id) {
            return -1;
          }
          if (b.userId === employee?.id) {
            return 1;
          }
          return 0;
        });
      }
      return records;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_employees_failed'));
    }
  },
);

export const requestMessages = createAsyncThunk(
  'messages/getMessages',
  async (employeeId, { extra, dispatch, getState }) => {
    const { messages: { message } } = getState();
    try {
      const params = {
        page: message.page,
        per_page: message.perPage,
        recipient_id: employeeId,
      };
      const response = await extra.axios.get('/api/v3/conversations/conversation', { params });
      const { records, ...rest } = getFormattedEmployeeMessages(response.data.data);
      return { records, filters: rest };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_employees_messages_failed'));
    }
  },
);

export const requestSendMessageToEmployee = createAsyncThunk(
  'messages/sendMessages',
  async (data, { extra, dispatch }) => {
    try {
      const body = {
        recipient_id: data.employeeId,
        message: {
          body: data.message,
          reply_to_message_id: data.replyMsgId,
        },
      };
      const response = await extra.axios.post('/api/v3/conversations', body);
      dispatch(requestMessages(data.employeeId));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_send_msg_employee_failed'));
    }
  },
);

export const requestSendMessageToMultipleEmployees = createAsyncThunk(
  'messages/sendMessageToEmployees',
  async (data, { extra, dispatch }) => {
    try {
      const body = getFormattedBodyForMessageToEmployees(data);
      await extra.axios.post('/api/v3/conversations/send_bulk_messages', body);
      if (data.activeChatEmployeeId) {
        dispatch(requestMessages(data.activeChatEmployeeId));
      }
    } catch (err) {
      dispatch(setSnackbarError('snackbar_send_messages_employees_failed'));
    }
  },
);

export const requestMarkEmployeeConversationsAsRead = createAsyncThunk(
  'messages/markAsRead',
  async ({ conversations, userId }, { extra, dispatch }) => {
    try {
      const updatePromises = conversations.map((conv) => {
        const body = {
          recipient_id: conv.userId,
          message: {
            read: true,
          },
        };
        return extra.axios.put(`/api/v3/conversations/${conv.id}`, body);
      });

      await Promise.all(updatePromises);
      dispatch(requestGetEmployees({ id: userId }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_employee_message_mark_all_read_error'));
    }
  },
);
