import pick from 'lodash/fp/pick';

export const getFormattedUserDataForRegister = (data = {}) => ({
  user: {
    ...pick(['email', 'password', 'name'])(data),
    password_confirm: data.passwordConfirmation,
  },
  company: {
    company_name: data.companyName,
    email: data.email,
    identification_num: data.identificationNum,
    org_type: data.orgType,
  },
});

export const getFormattedContractDetailsRequestBody = (details = {}) => ({
  contract_length: details.contractLength,
  name: details.name,
  identification_num: details.identificationNum,
  email: details.email,
  address: details.address,
  zip_code: details.zipCode,
  area: details.area,
  contact_person: details.contactPerson,
  mobile: details.mobile,
});

export const getDeviceDetails = () => {
  const { userAgent } = navigator;
  const { userAgentData } = navigator; // Modern API for structured data

  // Detect OS Name
  let osName = 'Unknown';
  if (/Windows/.test(userAgent)) osName = 'Windows';
  else if (/Mac/.test(userAgent)) osName = 'macOS';
  else if (/Android/.test(userAgent)) osName = 'Android';
  else if (/iPhone|iPad|iPod/.test(userAgent)) osName = 'iOS';

  // Detect OS Version
  let osVersion = 'Unknown';
  const osVersionMatch = userAgent.match(/(Windows NT|Android|CPU (iPhone )?OS|Mac OS X) ([\d_]+)/);
  if (osVersionMatch && osVersionMatch[4]) {
    osVersion = osVersionMatch[4].replace(/_/g, '.');
  }

  // Detect Browser Name and Version
  let browserName = 'Unknown';
  let browserVersion = 'Unknown';
  if (userAgentData) {
    const brands = userAgentData.brands || [];
    if (brands.length > 0) {
      browserName = brands[0].brand;
      browserVersion = brands[0].version;
    }
  } else if (/Chrome/.test(userAgent)) {
    browserName = 'Chrome';
    const versionMatch = userAgent.match(/Chrome\/([\d.]+)/);
    browserVersion = versionMatch ? versionMatch[1] : 'Unknown';
  } else if (/Firefox/.test(userAgent)) {
    browserName = 'Firefox';
    const versionMatch = userAgent.match(/Firefox\/([\d.]+)/);
    browserVersion = versionMatch ? versionMatch[1] : 'Unknown';
  } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
    browserName = 'Safari';
    const versionMatch = userAgent.match(/Version\/([\d.]+)/);
    browserVersion = versionMatch ? versionMatch[1] : 'Unknown';
  }

  return {
    modal_name: `${browserName} ${browserVersion}`,
    os_name: osName,
    os_version: osVersion,
    browser_name: browserName,
    browser_version: browserVersion,
    brand: osName,
  };
};
