import { fm } from '../string';
import { appRoutes } from './appRoutes';

export * from './names';
export * from './appRoutes';
export * from './permissions';
export * from './settings';

export const mobileAppType = {
  employeeAndroid: 1,
  employeeIos: 2,
  adminAndroid: 3,
  adminIos: 4,
};

export const employeeSalaryTypes = {
  hourly: 1,
  monthly: 2,
};

export const employeeSalaryTypeNames = {
  [employeeSalaryTypes.hourly]: fm('hourlyRates', 'Hourly Rates'),
  [employeeSalaryTypes.monthly]: fm('monthlyRates', 'Monthly Rates'),
};

export const userRoleType = {
  SuperAdmin: 1,
  owner: 2,
  administrator: 3,
  employee: 4,
};

export const userRoles = {
  owner: 'Owner',
  admin: 'Administrator',
  employee: 'Employee',
  superAdmin: 'SuperAdmin',
};

export const userRoleHomeRoutes = {
  [userRoles.superAdmin]: appRoutes.superAdminCompanies,
  [userRoles.owner]: appRoutes.home,
  [userRoles.admin]: appRoutes.home,
  [userRoles.employee]: appRoutes.employeeSchedule,
};

export const userRole = {
  employee: 1,
  admin: 2,
  owner: 3,
  company: 4,
};

export const userRoleNames = {
  [userRole.employee]: 'Employee',
  [userRole.admin]: 'Administrator',
  [userRole.owner]: 'Owner',
  [userRole.company]: 'Company',
};

export const userLogsUserRole = {
  admin: 1,
  employee: 2,
};

export const userLogsUserRoleNames = {
  [userLogsUserRole.admin]: 'Administrator',
  [userLogsUserRole.employee]: 'Employee',
};

export const requestStatus = {
  confirmed: 'Confirmed',
  cancelled: 'Canceled',
  pending: 'Pending',
};

export const requestStatusColor = {
  Confirmed: '#4caf50',
  Cancelled: '#f44336',
  Pending: '#f44336',
};

export const bookingStatus = {
  completed: 'Completed',
  cancelled: 'Canceled',
  active: 'Active',
};

export const bookingStatusColors = {
  Active: '#9e9e9e',
  Canceled: '#f44336',
  Completed: '#4caf50',
};

export const parentBookingStatus = {
  ongoing: 'ongoing',
  tohandle: 'toHandle',
  expired: 'Expired',
};

export const bookingPriceType = {
  fixed: 1,
  flexible: 2,
};

export const bookingPriceTypeNames = {
  [bookingPriceType.fixed]: fm('booking.modal.fixed'),
  [bookingPriceType.flexible]: fm('booking.modal.flexible'),
};

export const bookingFlexiblePriceType = {
  perItem: 1,
  perEmployeeHourly: 2,
};

export const bookingFlexiblePriceTypeNames = {
  [bookingFlexiblePriceType.perItem]: fm('table.price.flexible.item', 'Per Item'),
  [bookingFlexiblePriceType.perEmployeeHourly]: fm('table.price.flexible.employee', 'Per employee'),
};

export const weekDays = [
  { value: 'monday', label: fm('monday', 'Monday') },
  { value: 'tuesday', label: fm('tuesday', 'Tuesday') },
  { value: 'wednesday', label: fm('wednesday', 'Wednesday') },
  { value: 'thursday', label: fm('thursday', 'Thursday') },
  { value: 'friday', label: fm('friday', 'Friday') },
  { value: 'saturday', label: fm('saturday', 'Saturday') },
  { value: 'sunday', label: fm('sunday', 'Sunday') },
];

export const weekDaysShort = [
  { value: 'monday', label: fm('mon', 'Mon') },
  { value: 'tuesday', label: fm('tue', 'Tue') },
  { value: 'wednesday', label: fm('wed', 'Wed') },
  { value: 'thursday', label: fm('thu', 'Thu') },
  { value: 'friday', label: fm('fri', 'Fri') },
  { value: 'saturday', label: fm('sat', 'Sat') },
  { value: 'sunday', label: fm('sun', 'Sun') },
];

export const weekDaysOrder = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const shiftTimeTypes = {
  presence: 1,
  absence: 2,
  other: 3,
};

export const reportTimeTypeNames = {
  1: fm('timeReport.presence', 'Presence'),
  2: fm('timeReport.absence', 'Absence'),
  3: fm('timeReport_other', 'Other'),
};

export const calendarEventTypes = {
  booking: 1,
  request: 2,
  timeReport: 3,
  emptySlot: 4,
  punchTime: 5,
  order: 6,
};

export const calendarEventTypeNames = {
  1: 'Booking',
  2: 'Request',
  3: 'Time Report',
  4: 'Empty Slot',
  6: 'Order',
};

export const scheduledTypes = {
  1: '24h',
  2: '48h',
  3: '72h',
};

export const bookkeepingIntegrationTypes = {
  fortnox: 1,
  visma: 2,
};

export const bookkeepingIntegrationTypeNames = {
  1: 'Fortnox',
  2: 'Visma',
};

export const userJobTypeNames = {
  1: 'temporary',
  2: 'probation',
  3: 'holiday',
  4: 'onCall',
  5: 'permanent',
  6: 'limited',
  7: 'partTime',
  8: 'others',
};

export const monthRepeatType = {
  day: 1,
  date: 2,
};

export const projectMonthRepeatType = {
  day: 'day',
  date: 'date',
};

export const monthWeek = {
  first: 1,
  second: 2,
  third: 3,
  forth: 4,
  last: -1,
};

export const deviceType = {
  1: 'Mobile',
  2: 'Web',
};

export const frontendErrorStatusTypes = {
  notStarted: 1,
  inProgress: 2,
  completed: 3,
};

export const vismaItemVatTypes = {
  goods: 1,
  service: 2,
};

export const smsTemplateType = {
  active: 1,
  completed: 2,
  cancelled: 3,
  proposalCreate: 4,
};

export const proposalHouseworkType = {
  noHouework: 'no_housework',
  rut: 'rut',
  rot: 'rot',
};

export const invoiceDeliveryMethod = {
  1: fm('customer.select.email', 'Email'),
  2: fm('customer.select.print-out', 'Print out'),
  3: fm('customer.select.e_invoice', 'E-Invoice'), // only used in superadmin customers
};

export const invoiceDeliveryMethodType = {
  email: 1,
  print: 2,
  eInvoice: 3,
};

export const smsDataType = {
  CUSTOMER_NAME: '{CUSTOMER_NAME}',
  BOOKING_DATE: '{BOOKING_DATE}',
  BOOKING_TIME: '{BOOKING_TIME}',
  ORDER_TIME: '{ORDER_TIME}',
  START_TIME: '{START_TIME}',
  END_TIME: '{END_TIME}',
  ORDER_DATE: '{ORDER_DATE}',
  SERVICE_NAME: '{SERVICE_NAME}',
  COMPANY_NAME: '{COMPANY_NAME}',
  COMPANY_EMAIL: '{COMPANY_EMAIL}',
  COMPANY_MOBILE: '{COMPANY_MOBILE}',
  PROPOSAL_TIME: '{PROPOSAL_TIME}',
  PROPOSAL_DATE: '{PROPOSAL_DATE}',
  PROPOSAL_LINK: '{PROPOSAL_LINK}',
  CUSTOMER_ADDRESS: '{CUSTOMER_ADDRESS}',
  EMPLOYEE_NAME: '{EMPLOYEE_NAME}',
};

export const smsDataTypeAPI = {
  '{CUSTOMER_NAME}': 'CUSTOMER_NAME',
  '{BOOKING_DATE}': 'BOOKING_DATE',
  '{BOOKING_TIME}': 'BOOKING_TIME',
  '{ORDER_TIME}': 'ORDER_TIME',
  '{START_TIME}': 'START_TIME',
  '{END_TIME}': 'END_TIME',
  '{ORDER_DATE}': 'ORDER_DATE',
  '{SERVICE_NAME}': 'SERVICE_NAME',
  '{COMPANY_NAME}': 'COMPANY_NAME',
  '{COMPANY_EMAIL}': 'COMPANY_EMAIL',
  '{COMPANY_MOBILE}': 'COMPANY_MOBILE',
  '{PROPOSAL_LINK}': 'PROPOSAL_LINK',
  '{PROPOSAL_DATE}': 'PROPOSAL_DATE',
  '{PROPOSAL_TIME}': 'PROPOSAL_TIME',
  '{CUSTOMER_ADDRESS}': 'CUSTOMER_ADDRESS',
  '{EMPLOYEE_NAME}': 'EMPLOYEE_NAME',
};

export const bookingSmsButtonType = [
  'CUSTOMER_NAME',
  'BOOKING_TIME',
  'ORDER_TIME',
  'BOOKING_DATE',
  'ORDER_DATE',
  'START_TIME',
  'END_TIME',
  'SERVICE_NAME',
  'COMPANY_NAME',
  'COMPANY_EMAIL',
  'COMPANY_MOBILE',
  'CUSTOMER_ADDRESS',
  'EMPLOYEE_NAME',
];

export const proposalSmsButtonType = [
  'CUSTOMER_NAME',
  'PROPOSAL_DATE',
  'PROPOSAL_TIME',
  'START_TIME',
  'END_TIME',
  'SERVICE_NAME',
  'COMPANY_NAME',
  'COMPANY_EMAIL',
  'COMPANY_MOBILE',
  'PROPOSAL_LINK',
  'CUSTOMER_ADDRESS',
];

export const customerRequestType = {
  status: 1,
  date: 2,
};

export const constitutionMessageTypes = {
  bookingDate: 1,
  bookingStatus: 2,
};

export const customerRequestStatusType = {
  pending: 1,
  approved: 2,
  denied: 3,
};

export const supportedAttachmentType = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  png: 'image/png',
};

export const keyStatusType = {
  in: 1,
  out: 2,
};

export const keyStatusTypeName = {
  [keyStatusType.in]: fm('in'),
  [keyStatusType.out]: fm('out'),
};

export const bookingTimeTypes = {
  specificTime: 1,
  totalHours: 2,
  flexTime: 3,
};

export const proposalTimeTypesString = {
  specificTime: 'specific_time',
  totalHours: 'total_hours',
  flexTime: 'flex_time',
};

export const proposalBookingTimeTypes = {
  specific_time: 1,
  total_hours: 2,
  flex_time: 3,
};

export const bookingFlexTimeType = {
  flex: 1,
  morning: 2,
  afternoon: 3,
  evening: 4,
};

export const proposalFlexTimeTypeString = {
  flex: 'flex',
  morning: 'morning',
  afternoon: 'afternoon',
  evening: 'evening',
};

export const otherCodeSalaryType = {
  flexible: 1,
  fixed: 2,
};

export const documentTypes = {
  companyLogo: 1,
  userProfileLogo: 2,
};

export const employeeBookingTimeStatus = {
  0: fm('not_approved'),
  1: fm('approved'),
};

export const userAgentTypes = {
  mobileBrowser: 'mobile_browser',
  computerBrowser: 'computer_browser',
  mobileAppOnline: 'mobile_app_online',
  mobileAppOffline: 'mobile_app_offline',
};

export const signUpSteps = {
  initial: 0,
  companyDetails: 1,
  integration: 2,
  createEmployee: 3,
};

export const organisationTypes = {
  soleTrader: 'sole_trader',
  limitedLiability: 'limited_liability',
  tradingPartnership: 'trading_partnership',
  other: 'other',
};

export const proposalPolicyTypes = {
  introText: 1,
  cancellationPolicy: 2,
  companyPolicy: 3,
};

export const proposalPolicyTabsName = {
  1: fm('proposal_email_msg'),
  2: fm('proposal_cancellation_policy'),
  3: fm('proposal_company_policy'),
};

export const proposalRequestStatusTypes = {
  approved: 'approved',
  cancelled: 'cancelled',
  signed: 'signed',
};

export const itemSalesTypes = {
  1: fm('stock'),
  2: fm('item_service'),
};

export const projectTimeTypesString = {
  specificTime: 'specific_time',
  totalHours: 'total_hours',
  flexTime: 'flex_time',
};

export const projectFlexTimeTypeString = {
  flex: 'flex',
  morning: 'morning',
  afternoon: 'afternoon',
  evening: 'evening',
};

export const orderStatus = {
  completed: 'Completed',
  cancelled: 'Canceled',
  active: 'Active',
  freeze: 'Freeze',
};

export const orderRecurringUpdateOptions = {
  current: 'current',
  futureActiveOrders: 'future_active_order',
  allActiveOrders: 'all_active_order',
};

export const orderHouseworkType = {
  none: 'None',
  rut: 'Rut',
  rot: 'Rot',
};

export const orderPriceType = {
  pricePerItem: 'price_per_item',
  pricePerEmployee: 'price_per_employee',
  fixed: 'fixed',
};

export const chatDialogTabsTypes = {
  order: 0,
  booking: 1,
};

export const chatDialogTabs = [
  {
    id: 'order',
    title: fm('order', 'Order'),
  },
  {
    id: 'booking',
    title: fm('booking', 'Booking'),
  },
];

export const customerRequestTabs = [
  {
    title: fm('order', 'Order'),
  },
  {
    title: fm('booking', 'Booking'),
  },
];

export const punchTimeSettingOptions = {
  anytime: 'anytime',
  punchDuration: 'punchDuration',
};

export const reportExportTypes = {
  excel: 'excel',
  paxml: 'paxml',
};

export const productUpdatesTypes = {
  fix: 'fix',
  news: 'news',
};

export const productUpdatesStatusTypes = {
  open: 'open',
  reopen: 'reopen',
  inProgress: 'in_progress',
  toDo: 'to_do',
  done: 'done',
};

export const productUpdatesSeverityTypes = {
  low: 'low',
  medium: 'medium',
  high: 'high',
};

export const productUpdateStatusOptions = [
  { value: 'open', label: fm('open') },
  { value: 'reopen', label: fm('reopen') },
  { value: 'in_progress', label: fm('in_progress') },
  { value: 'to_do', label: fm('to_do') },
  { value: 'done', label: fm('done') },
];

export const productUpdateSeverityOptions = [
  { value: 'low', label: fm('low') },
  { value: 'medium', label: 'medium' },
  { value: 'high', label: fm('high') },
];

export const productUpdateTypeOptions = [
  { value: 'news', label: fm('product_news') },
  { value: 'fix', label: fm('product_update') },
];

export const employeeRequestTypes = {
  requestForHoliday: 'requestForHoliday',
  requestForWork: 'requestForWork',
};

export const openShiftStatusTypes = {
  approved: 'Approved',
  denied: 'Denied',
  awaiting: 'Awaiting',
  available: 'Available',
  requested: 'Requested',
};
