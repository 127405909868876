import { getFormattedPaginationFields } from 'appRedux/utility';
import { orderBy } from 'utils/lodash';

export const getFormattedEmployeeMessages = (response) => {
  const orderedRecords = orderBy('createdAt', 'asc')(response.records || []);
  return (
    {
      ...getFormattedPaginationFields(response),
      records: orderedRecords,
    }
  );
};

export const getFormattedBodyForMessageToEmployees = (data) => ({
  recipient_ids: data.sendAll
    ? data.employees.map((emp) => emp.userId)
    : data.employeesId,
  message: {
    body: data.msg,
  },
});
