import { fm } from 'utils/string';
import filterChipsConfig from 'components/FiltersCommon/filterChipsConfig';
import { formatDate } from 'utils/dateTime';
import { dateFnsLocalizer, Views } from 'react-big-calendar';
import enLocale from 'date-fns/locale/en-US';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';

export const calendarView = {
  calendarWeekBookings: 'calendarWeekBookings',
  calendarWeekEmployees: 'calendarWeekEmployees',
  calendarMonth: Views.MONTH,
  schedulerDay: 'schedulerDay',
  schedulerMonth: 'schedulerMonth',
  schedulerWeek: 'schedulerWeek',
};

export const bigCalendarConfig = {
  localizer: dateFnsLocalizer({
    format: formatDate,
    parse,
    startOfWeek: (date) => startOfWeek(date, { weekStartsOn: 1 }),
    getDay,
    locales: { 'en-US': enLocale },
  }),
  defaultView: calendarView.calendarWeekBookings,
  calendarViews: [Views.WEEK, Views.MONTH],
};

export const calendarWeekView = {
  employee: 1,
  booking: 2,
};

const requestFormatter = (val) => (val ? fm('requests') : null);

const punchesFormatter = (val) => (val ? fm('punches') : null);

const deactivatedEmployeesFormatter = (val) => (val ? fm('deactivated') : null);

export const filtersData = [
  filterChipsConfig.bookingStatus,
  filterChipsConfig.department,
  filterChipsConfig.area,
  filterChipsConfig.customerArea,
  filterChipsConfig.customerZipcode,
  filterChipsConfig.skills,
  filterChipsConfig.customer,
  filterChipsConfig.employees,
  filterChipsConfig.services,
  filterChipsConfig.tag,
  filterChipsConfig.invoiceStatus,
  filterChipsConfig.userGroup,
  filterChipsConfig.publishStatus,
  { accessor: 'supervisor.name', name: 'supervisor', title: fm('supervisor') },
];

export const isMobileDevice = (size) => ['xs', 'sm'].includes(size);
export const isScreenSizeSuitableForFilterChips = (size) => ['xs', 'sm', 'md', 'lg'].includes(size);
